.dropdown-item.btn-link:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #303333;
}

select {
  -webkit-appearance: none;
}

input.form-control.ignore-read-only-style {
  box-shadow: inset 0 0 0 1px #eceeef;
  cursor: pointer;
}
input.form-control.ignore-read-only-style.not-bg-white {
  color: black;
  background-color: rgba(110, 119, 122, 0.15);
}
input.form-control.ignore-read-only-style:hover {
  box-shadow: inset 0 0 0 1px black;
}

input.form-control.ignore-read-only-style:focus {
  color: black;
  background-color: rgba(110, 119, 122, 0.15);
  border-color: #6e7575;
  outline: 0;
  box-shadow: inset 0 0 0 2px black;
}

div.not-bg-white {
  input.form-control.ignore-read-only-style {
    background-color: rgba(149, 161, 162, 0.2);
  }
}

input.form-control.read-only-item {
  background-color: rgba(149, 161, 162, 0.2);
  box-shadow: none;
  cursor: default;
}

div.card-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 32px;
  z-index: 1000;
}

div.card-dropdown-overflow {
  max-height: 200px;
  overflow-y: auto;
}

@mixin cardDropdownSize($size, $pixels) {
  div.input-group-#{$size} {
    div.card-dropdown {
      top: #{$pixels}px;
    }
  }
}

@include cardDropdownSize("lg", 43);
@include cardDropdownSize("xl", 48);
@include cardDropdownSize("sm", 20);
