body {
  background-color: white;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

header,
footer {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
  padding-top: 90px;
  padding-bottom: 54px;
}

div[role="main"] {
  margin-top: 73px;
}

i.icon {
  margin-right: 5px;
}

/* Sg cards are ugly. Try to improve it a bit */
.card {
  border: 1px solid #00000020;
  /* To prevent bleed when dynamically loading a component */
  overflow: hidden;
}

.table th,
.table td {
  vertical-align: middle;
}
