@import "variables";

.featureLoader {
  overflow-x: hidden;
  position: absolute !important;
  display: flex;
  height: 100%;
  z-index: 1000;
  min-height: 90px;
  top: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  background: rgba(207, 207, 207, 0.4);

  > span {
    align-self: center;
    z-index: 100;
    min-width: 110px;
  }

  .loadingIndicator {
    width: 16px;
    height: 16px;
    padding-left: 25px;
    background: $loader-image no-repeat top left;
    background-size: 16px 16px;
    display: inline-block;
  }

  .loadingContainer {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid lightgray;
  }
}
